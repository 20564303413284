<template>
  <v-list v-if="!!main_menu_list">
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router
        :to="item.route"
        color="orange"
        @click="$emit('closeDrawer')"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['drawer'],
  name: 'Sidebar',

  data() {
    return {
      items: [
        { icon: 'mdi-home', title: 'Home', route: '/' },
        {
          icon: 'mdi-comment-quote-outline',
          title: 'Feedback',
          route: '/feedback'
        }
        // { icon: 'mdi-information', title: 'About Us', route: '/about' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      main_menu_list: 'main_menu_list'
    })
  }
}
</script>

<style>
</style>