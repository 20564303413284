<template>
  <v-app>
    <div
      v-touch="{
        right: () => changeDrawer()
      }"
    >
      <Navbar @changeDrawer="changeDrawer" />
      <div class="sidebar">
        <v-navigation-drawer v-model="drawer" app temporary class="mt-12" fixed>
          <Sidebar @closeDrawer="closeDrawer" />
        </v-navigation-drawer>
      </div>

      <v-main class="pa-0">
        <router-view></router-view>
      </v-main>

      <v-footer app> </v-footer>
    </div>
  </v-app>
</template>

<script>
import Navbar from './Navbar.vue'
import Sidebar from './Sidebar.vue'
export default {
  name: 'Index',
  components: {
    Navbar,
    Sidebar
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    changeDrawer() {
      this.drawer = !this.drawer
    },
    closeDrawer() {
      this.drawer = false
    }
  }
}
</script>

<style>
</style>